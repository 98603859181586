<template>
  <el-form
    size="small"
    ref="editForm"
    label-position="top"
    :model="scale"
    :rules="rules"
  >
    <el-form-item label="Целевые значения">
      <el-row type="flex" justify="space-between">
        <el-col :span="7">
          <el-form-item prop="target_a">
            <div>Min</div>
            <el-date-picker
              v-model="scale.target_a"
              :disabled="true"
              size="small"
              :picker-options="{firstDayOfWeek: 1}"
              type="date"
              value-format="yyyy-MM-dd"
              format="dd.MM.yyyy"
            >
            </el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="7">
          <el-form-item prop="target_b">
            <div>Цель</div>
            <el-date-picker
              v-model="scale.target_b"
              :disabled="true"
              size="small"
              :picker-options="{firstDayOfWeek: 1}"
              type="date"
              value-format="yyyy-MM-dd"
              format="dd.MM.yyyy"
            >
            </el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="7">
          <el-form-item prop="target_c">
            <div>Max</div>
            <el-date-picker
              v-model="scale.target_c"
              :disabled="true"
              size="small"
              :picker-options="{firstDayOfWeek: 1}"
              type="date"
              value-format="yyyy-MM-dd"
              format="dd.MM.yyyy"
            >
            </el-date-picker>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form-item>
    <el-form-item
      label="Выплаты за достижения, %"
    >
      <el-row type="flex" justify="space-between">
        <el-col :span="7">
          <el-form-item prop="pay_a">
            <el-input type="number" v-model="scale.pay_a" :disabled="!fieldsPermissions['scale.pays']">
              <template slot="prepend">Min</template>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="7">
          <el-form-item prop="pay_b">
            <el-input type="number" v-model="scale.pay_b" :disabled="!fieldsPermissions['scale.pays']">
              <template slot="prepend">Target</template>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="7">
          <el-form-item prop="pay_c">
            <el-input type="number" v-model="scale.pay_c" :disabled="!fieldsPermissions['scale.pays']">
              <template slot="prepend">Max</template>
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form-item>
  </el-form>
</template>

<script>

import {dynamicScaleComponent} from "@/mixins/dynamicScaleComponent";
import {mapGetters} from "vuex";

export default {
  name: "card-edit-form",
  mixins: [dynamicScaleComponent],
  components: {},

  props: {},

  computed: {},

  watch: {},

  mounted() {
    if( !this.scale.pay_b ){
      this.scale.pay_b = 100;
    }
    this.$nextTick(function () {
      this.$refs.editForm.clearValidate();
    })
  },

  data() {
    const validatePayDirection = (rule, value, callback) => {
      if( rule.field === 'pay_a' ){
        this.$refs.editForm.validateField('pay_c');
        callback();
      }
      if( rule.field === 'pay_b' ){
        this.$refs.editForm.validateField('pay_c');
        callback();
      }
      if( rule.field === 'pay_c' ){
        if( +this.scale.pay_c < +this.scale.pay_b ){
          callback(new Error('Max не может быть меньше Target'));
        }
        if( +this.scale.pay_b > +this.scale.pay_c ){
          callback(new Error('Target не может быть больше Max'));
        }
        if( +this.scale.pay_b < +this.scale.pay_a ){
          callback(new Error('Target не может быть меньше Min'));
        }
        if( +this.scale.pay_a > +this.scale.pay_b ){
          callback(new Error('Min не может быть больше Target'));
        }
        callback();
      }
    };
    const validateEqualValues = (rule, value, callback) => {
      if( rule.field === 'target_b' ){
        if( this.scale.target_a == this.scale.target_b && this.scale.pay_a != this.scale.pay_b){
          callback(new Error('При одинаковых целевых должны быть одинаковые выплаты'));
        }
        if( this.scale.target_c == this.scale.target_b && this.scale.pay_c != this.scale.pay_b){
          callback(new Error('При одинаковых целевых должны быть одинаковые выплаты'));
        }
        callback();
      } else {
        this.$refs.editForm.validateField('target_b');
        callback();
      }
    };
    const validateValue = (rule, value, callback) => {
      if( value < rule.min || value > rule.max ){
        callback(new Error('Минимум '+rule.min+', максимум '+rule.max+''));
      }
      callback();
    };

    return {
      scale:{
        auto_bonus: false,

        target_a: null,
        target_b: null,
        target_c: null,
        pay_a: null,
        pay_b: null,
        pay_c: null,
        fact: null,
      },

      rules: {
        pay_a: [
          { validator: validatePayDirection, trigger: 'change'},
          { validator: validateEqualValues, trigger: 'change'},
          { validator: validateValue, min: 0, max: 120, trigger: 'blur' },
        ],
        pay_b: [
          { validator: validatePayDirection, trigger: 'change'},
          { validator: validateEqualValues, trigger: 'change'},
          { validator: validateValue, min: 0, max: 120, trigger: 'blur' },
        ],
        pay_c: [
          { validator: validatePayDirection, trigger: 'change'},
          { validator: validateEqualValues, trigger: 'change'},
          { validator: validateValue, min: 0, max: 120, trigger: 'blur' },
        ],

        target_a: [{ validator: validateEqualValues, trigger: 'change'}],
        target_b: [{ validator: validateEqualValues, trigger: 'change'}],
        target_c: [{ validator: validateEqualValues, trigger: 'change'}],
      }


    }
  },

  methods: {

  }
}
</script>

<style>

</style>